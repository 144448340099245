html, body, #root, .App {
    height: 100%;
}

body {
    margin: 0;
}
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #D7D7D7;
    border-radius: 8px;
    min-height: 48px;
}


